<template lang="pug">
.basic-box-shadow.border-rounded.w-100.foreground-color.mb-4.attraction-link
	h2.attraction-link__subtitle(:class="classTitle") {{ title }}

	.attraction-link__inner(v-if="paywallPage")
		span.b3.mb-10.text-grey 🔗 {{ $t('project.webLink') }}
		.d-flex.justify-content-between.align-items-center.mt-10.position-relative.bot-setup__input(v-if="!isMobile")
			a.b1.text-break.mr-10.attraction-link__link(:href="paywallLink" target="_blank") {{ paywallLink }}

			.d-flex
				i.text-primary.fontello.fontello-icon-edit.cursor-pointer.btn-icon.d-none.d-sm-block.mr-10(@click="$router.push({name: 'project_settings', params: {id: item.id}})")
				.justify-content-center.align-items-center.btn-icon.d-none.d-sm-flex
					pw-icon-copy(:text="paywallLink", :textSuccess="`${this.$t('success.webLinkCopied')}`")
				i.text-primary.fontello.btn-icon.cursor-pointer.fontello-icon-scan-barcode-1.ml-10.d-none.d-sm-block(@click='openModalQRCode()')
		
		.d-flex.justify-content-between(v-if="isMobile")
			a.attraction-link__btn(:href='paywallLink' target='_blank')
				i.fontello-btn.fontello-btn-primary.fontello-icon-link-1
			
			.attraction-link__btn(@click="openModalQRCode()")
				i.fontello-btn.fontello-btn-primary.fontello-icon-scan-barcode-1

		modal-general(v-if="!isMobile" :id="'edit-paywall-page'" :title="$t('project.webLink')")
			edit-project-link(:item='itemEditLinkPaywall' @close="$bvModal.hide('edit-paywall-page')")

		b-modal(v-if="isMobile" :id="'edit-paywall-page'" content-class="attraction-link__modal" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 {{ $t('project.addressPaymentPageAddon') }}
				icon-close(@click="close()")
			edit-project-link(:item='itemEditLinkPaywall' @close="$bvModal.hide('edit-paywall-page')")

	.attraction-link__inner(v-if="telegramPage")
		span.b3.mb-10.text-grey 🔗 {{ $t('bots.settings.webLink') }}
		.d-flex.justify-content-between.align-items-center.mt-10.position-relative.bot-setup__input(v-if="!isMobile")
			a.b1.text-break.mr-10.attraction-link__link(:href="`https://t.me/${login.replace('@', '')}`" target="_blank") https://t.me/{{ login.replace('@', '') }}
			.justify-content-center.align-items-center.btn-icon.d-none.d-sm-flex
				pw-icon-copy(:text="`https://t.me/${login.replace('@', '')}`", :textSuccess="`${this.$t('success.webLinkCopied')}`")
			
		.d-flex.justify-content-between(v-if="isMobile")
			a.attraction-link__btn(:href="`https://t.me/${login.replace('@', '')}`" target="_blank")
				i.fontello-btn.fontello-btn-primary.fontello-icon-link-1
			
			.attraction-link__btn(@click="openModalQRCode()")
				i.fontello-btn.fontello-btn-primary.fontello-icon-scan-barcode-1

	div(v-if="botUsername")
		span.mb-10.text-grey 🔗 {{ $t('bots.settings.usernameOfBot') }}

		.d-flex.justify-content-between
			.d-flex.justify-content-between.align-items-center.mt-10.position-relative.bot-setup__input
				a.b1.text-break.mr-10.attraction-link__link.text-link(:href="`https://t.me/${login.replace('@', '')}`" target="_blank") {{ login }}
				.justify-content-center.align-items-center.btn-icon.d-none.d-sm-flex(v-if="!isMobile")
					pw-icon-copy(:text="`${login}`", :textSuccess="`${this.$t('success.usernameCopied')}`")
				
			pw-icon-copy.fontello-btn.fontello-btn-primary.attraction-link__btn.is-copy(v-if="isMobile" :text="`${login}`", :textSuccess="`${this.$t('success.usernameCopied')}`")

	modal-qr-code(:projectImage='botImage' :projectName='botName' :qrPaymentPage="qrPaymentPage" :isNeurobot="true")

</template>

<script>
import { mapGetters } from 'vuex';
import PwIconCopy from "@/components/Form/PwIconCopy";
import ModalGeneral from '@/components/Modals/ModalGeneral';
import EditProjectLink from '@/components/Common/EditProjectLink';
import IconClose from "@/components/Common/IconClose";
import ModalQRCode from '@/components/Modals/ModalQRCode';

export default {
	name: 'AttractionLink',
	components: {
		PwIconCopy,
		ModalGeneral,
		EditProjectLink,
		IconClose,
		'modal-qr-code': ModalQRCode
	},
	data: () => ({
		overlay	: false,
		itemEditLinkPaywall : null,
	}),
	props: {
		login: {
			default : '',
			type : String
		},
		title: {
			default : '',
			type : String
		},
		classTitle: {
			default : '',
			type : String
		},
		telegramPage: {
			default : false,
			type : Boolean
		},
		botUsername: {
			default : false,
			type : Boolean
		},
		paywallPage: {
			default : false,
			type : Boolean
		},
		paywallLink: {
			default : '',
			type : String
		},
		botImage: {
			default : '',
			type : String
		},
		botName: {
			default : '',
			type : String
		}
	},
	computed : {
		...mapGetters({
			item: 'project/opened',
			qrPaymentPage: 'project/qrPaymentPage',
		}),
	},
	created() {
		window.addEventListener('click', e => {
			if(this.overlay === true) {
				const target = e.target;

				if (!target.closest('.b-popover') && !target.closest('.bot-setup__dots')) {
					this.onClosePopover();
				}
			}
		})
	},
	methods : {
		onOpenPopover() {
			document.body.classList.add("overlay");
			this.overlay = true;
		},
		onClosePopover() {
			if (this.$refs.popoverMenuDotsLinkTelegram) {
				this.$refs.popoverMenuDotsLinkTelegram.$emit('close');
			}

			if (this.$refs.popoverMenuDotsLinkPaywall) {
				this.$refs.popoverMenuDotsLinkPaywall.$emit('close');
			}

			if (this.$refs.popoverMenuDotsUsername) {
				this.$refs.popoverMenuDotsUsername.$emit('close');
			}

			document.body.classList.remove('overlay');
			this.overlay = false;
		},
		editLink() {
			this.onClosePopover();
			this.itemEditLinkPaywall = null;

			this.$nextTick( () => {
				this.itemEditLinkPaywall = this.item;
			})
		},
		openModalQRCode() {
			this.$bvModal.show(`show-qr-code`);
			this.onClosePopover();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.attraction-link {
	@include respond-below(sm) {
		padding: 14px 17px !important;
	}

	&__subtitle {
		margin-bottom: 10px !important;

		@include respond-below(xs){
			margin-bottom: 21px !important;
		}
	}

	&__inner {
		margin-bottom: 10px;

		@include respond-below(xs){
			margin-bottom: 23px;
		}
	}

	&__link {
		@include respond-below(sm) {
			margin-right: 0;
		}
		
		@include respond-below(xs){
			font-size: 14px !important;
		}
	}

	&__btn {
		width: calc(50% - 5px);
		min-height: 44px;
		margin-top: 10px;
		display: flex;
		padding: 0;
		align-items: center;
		justify-content: center;

		&.is-copy {
			max-width: 44px;
			width: 44px;
			margin-left: 15px;
			margin-top: 13px;
			font-size: 18px;
		}

		i {
			display: block;
			width: 100%;
			height: 100%;
			text-align: center;
			padding: 0;
			font-size: 18px;

			&::before {
				line-height: 44px;
			}
		}
	}

	.bot-setup {
		&__input {
			width: 100%;

			@include respond-below(sm) {
				padding: 11px 15px;
				width: calc(100% - 59px);
				margin-top: 13px;
				min-height: 44px;
				border-radius: 8px;
			}
		}
	}
}

</style>
